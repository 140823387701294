import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {
  Box,
  Breadcrumbs,
  Container,
  Divider,
  Paper,
  Typography,
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useLocation } from 'react-router-dom';
import useStatesFields from '../../../components/MultipleFilters/hooks/useStatesFields';
import ReturnButton from '../../../components/ReturnButton';
import { SideMenu } from '../../../components/SideMenu';
import { TableReportsCitologiaReflexo } from '../../../components/TableReports/TableCitologiaReflexo/TableReportsCitologiaReflexo';
import { Option } from '../../../types/Option';
import FiltersReportsCitologiaReflexo from './FiltersReportsCitologiaReflexo';
import { getCidades } from '../../../lib/getCidadesEstabelecimentos';
import useGetDetailsFilters from '../ReportsTrackingHpv/FiltersReportsTrackingHpv/hooks/useGetDetailsFilters';
import useHandleChangeFilters from './FiltersReportsCitologiaReflexo/hooks/useHandleChangesFilters';
import { getUserToken } from '../../../lib/auth';
import api from '../../../api';
import { DisplayFilter, ItemsDisplayFilter } from '../../Convocation/styles';
import { LoadingContext } from '../../../context/LoadingContext';

// const RequiredText = () => {
//   return (
//     <div style={{ margin: '3px 0 0', fontSize: '12px' }}>
//       <p style={{ margin: 0, fontStyle: 'italic', color: 'rgba(0,0,0,0.6)' }}>
//         <span style={{ color: 'red' }}>* </span>Obrigatório
//       </p>
//     </div>
//   );
// };

const initialStateListFilter = [
  { inputName: 'estado', value: '' },
  { inputName: 'municipio', value: '' },
  { inputName: 'estabelecimento_saude', value: '' },
  { inputName: 'resultado', value: '' },
  { inputName: 'profissionalLaudo', value: '' },
  { inputName: 'data_ini', value: '' },
  { inputName: 'data_fin', value: '' },
  { inputName: 'search', value: '' },
];

export const ReportsCitologiaReflexo = () => {
  const [tableData, setTableData] = useState({
    data: [],
    page: 1,
    count: 25,
    totalPages: 1,
    totalItems: 1,
  });
  const [, setEstadosSelecionados] = useState<Option[]>([]);
  const [, setMunicipiosSelecionados] = useState<Option[]>([]);
  const [, setLaboratoriosSelecionados] = useState<Option[]>([]);
  const [, setResultadoCitologia] = useState<Option[]>([]);
  const [period, setPeriod] = useState('period');
  const [showTableData, setShowTableData] = useState(false);
  const [listFilterShow, setListFilterShow] = useState<any[]>([]);
  const [listFilter, setListFilter] = useState<any[]>(initialStateListFilter);
  const [selectedResultado, setSelectedResultado] = useState<any>();
  const [selectedLaboratorio, setSelectedLaboratorio] = useState<any>();

  const [dtColetaIni, setDataColetaIni] = useState<any>(null);

  const [dtColetaFinal, setDataColetaFinal] = useState<any>(null);

  const { setLoading } = useContext(LoadingContext);
  const location = useLocation();
  const { origin, payload } = location.state || {};

  const [selectedOptionsLaboratorio, setSelectedOptionsLaboratorio] = useState<
    any[]
  >([]);

  const [disabledProfissionalResultado, setDisabledProfissionalResultado] =
    useState(false);
  const [disabledLaboratorioResultado, setDisabledLaboratorioResultado] =
    useState(false);

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<any>({
    defaultValues: {
      page: 1,
      size: 25,
    },
  });

  const {
    estadosSelected,
    municipiosSelected,
    estabelecimentosSelected,
    equipesSelected,
    disableInputEstado,
    disableMunicipioInput,
    disableEstabelecimentoInput,

    setEstadosSelected,
    setMunicipiosSelected,
    setEstabelecimentosSelected,
    setDisableInputEstado,
    setDisabledMunicipioInput,
    setDisabledEstabelecimentoInput,
    setOptionsMunicipios,
  } = useStatesFields();

  const { getMunicipioDetails } = useGetDetailsFilters();

  const { handleSetFilter } = useHandleChangeFilters({
    setListFilter,
  });

  const clearValuesInputs = () => {
    setEstabelecimentosSelected([]);
    setDataColetaFinal('');
    setDataColetaIni('');
    setSelectedResultado([]);

    setListFilter([
      { inputName: 'estado', value: estadosSelected[0] },
      { inputName: 'municipio', value: '' },
      { inputName: 'estabelecimento_saude', value: '' },
      { inputName: 'resultado', value: '' },
      { inputName: 'profissionalLaudoId', value: '' },
      { inputName: 'data_ini_coleta', value: '' },
      { inputName: 'data_fin_coleta', value: '' },
      { inputName: 'data_ini_resultado', value: '' },
      { inputName: 'data_fin_resultado', value: '' },
      { inputName: 'search', value: '' },
    ]);

    setValue('profissionalLaudoId', '');
  };

  const setFiltersWhenPadrao = async (userToken) => {
    setShowTableData(true);
    setDisableInputEstado(true);
    setDisabledMunicipioInput(true);
    setDisabledEstabelecimentoInput(true);
    return new Promise(async (resolve) => {
      let payload = {};
      setEstadosSelected(['PE']);

      const optionCidade = [
        {
          id: userToken.endereco.municipio_id,

          nome: userToken.endereco.municipio_nome,
          codigo: userToken.endereco.municipio_code,
          estadoSigla: userToken.endereco.estado,
        },
      ];

      setOptionsMunicipios(optionCidade);
      setMunicipiosSelected([optionCidade[0].id]);
      setEstabelecimentosSelected([userToken.estabelecimentoId]);
      payload = {
        page: 1,
        size: 25,
        estados: 'PE',
        municipios: optionCidade[0].id,
        estabelecimentoSaudeId: userToken.estabelecimentoId,
      };

      setListFilterShow([
        {
          inputName: 'estado',
          value: 'Estado: PE',
        },
        {
          inputName: 'municipio',
          value: `Municipio: ${userToken.endereco.municipio_nome}`,
        },
        {
          inputName: 'estabelecimento_saude',
          value: `Estabelecimento de Saúde: ${userToken.estabelecimento_saude.nome_fantasia}`,
        },
      ]);

      setListFilter([
        { inputName: 'estado', value: 'Estado: PE' },
        {
          inputName: 'municipio',
          value: `Municipio: ${userToken.endereco.municipio_nome}`,
        },
        {
          inputName: 'estabelecimento_saude',
          value: `Estabelecimento de Saúde: ${userToken.estabelecimento_saude.nome_fantasia}`,
        },
        { inputName: 'resultado', value: '' },
        { inputName: 'profissionalLaudo', value: '' },
        { inputName: 'data_ini', value: '' },
        { inputName: 'data_fin', value: '' },
        { inputName: 'search', value: '' },
      ]);
      resolve(payload);
    });
  };

  const setFiltersToRequestByTable = async () => {
    setShowTableData(true);
    setEstadosSelected(payload.params.estados ? [payload.params.estados] : []);
    setMunicipiosSelected(
      payload.params.municipios ? [payload.params.municipios] : []
    );
    setEstabelecimentosSelected(
      payload.params.estabelecimentos ? [payload.params.estabelecimentos] : []
    );

    if (payload.params.periodo_inicio && payload.params.periodo_fim) {
      setDataColetaIni(payload.params.periodo_inicio);
      setDataColetaFinal(payload.params.periodo_fim);
    }

    const updatedListFilterShow = [
      { inputName: 'estado', value: `Estado: ${payload.params.estados || ''}` },
      {
        inputName: 'municipio',
        value: `Município: ${payload.params.municipios || ''}`,
      },
    ].filter(
      (item) => item.value !== 'Estado: ' && item.value !== 'Município: '
    );

    setListFilterShow(updatedListFilterShow);
    setListFilter([...updatedListFilterShow, ...initialStateListFilter]);

    return {
      ...payload.params,
      page: 1,
      size: 25,
    };
  };

  const setFiltersWhenMunicipal = async (userToken) => {
    setShowTableData(true);
    setDisableInputEstado(true);
    setDisabledMunicipioInput(true);
    setDisabledEstabelecimentoInput(false);

    return new Promise(async (resolve) => {
      let payload = {};
      setEstadosSelected(['PE']);

      const optionsCidades = await getCidades('PE');

      if (optionsCidades.length > 0) {
        setOptionsMunicipios(optionsCidades);
        const municipio = await getMunicipioDetails(
          userToken.endereco.municipio_nome
        );

        setMunicipiosSelected([municipio[0].id]);

        payload = {
          page: 1,
          size: 25,
          estados: 'PE',
          municipios: municipio[0].id,
        };

        setListFilterShow([
          {
            inputName: 'estado',
            value: 'Estado: PE',
          },
          {
            inputName: 'municipio',
            value: `Municipio: ${userToken.endereco.municipio_nome}`,
          },
          {
            inputName: 'estabelecimento_saude',
            value: ``,
          },
        ]);

        setListFilter([
          { inputName: 'estado', value: 'Estado: PE' },
          {
            inputName: 'municipio',
            value: `Municipio: ${userToken.endereco.municipio_nome}`,
          },
          {
            inputName: 'estabelecimento_saude',
            value: ``,
          },
          { inputName: 'resultado', value: '' },
          { inputName: 'profissionalLaudo', value: '' },
          { inputName: 'data_ini', value: '' },
          { inputName: 'data_fin', value: '' },
          { inputName: 'search', value: '' },
        ]);
        resolve(payload);
      }
    });
  };

  const setFiltersWhenEstadual = async () => {
    setShowTableData(false);
    setDisableInputEstado(true);

    return new Promise(async () => {
      let payload = {};
      setEstadosSelected(['PE']);

      payload = {
        page: 1,
        size: 25,
        estados: 'PE',
      };

      setListFilterShow([
        {
          inputName: 'estados',
          value: 'Estado: PE',
        },
      ]);

      handleSetFilter('Estado: PE', 'estados');
      // setLoading(false);

      // reject();
    });
  };

  const userToken = getUserToken();

  const getReportsResultadosCitologia = async (payloadToRequest) => {
    try {
      const response = await api.get('/reports/visualizar/citologia', {
        params: {
          ...payloadToRequest,
          tipo: 'Reflexo',
        },
      });
      setTableData(response.data);
      setShowTableData(true);
    } catch (error) {
      console.error('Erro ao buscar dados de citologia:', error);
    } finally {
      setLoading(false);
    }
  };

  const isMedicoLaboratorio =
    userToken.permissao_atual.contexto === 'LABORATÓRIO' &&
    userToken.permissao_atual.id === 7;

  const isAdminLaboratorio =
    userToken.permissao_atual.contexto === 'LABORATÓRIO' &&
    userToken.permissao_atual.nome === 'Admin Estabelecimento';

  const isOutrosLaboratorio =
    userToken.permissao_atual.contexto === 'LABORATÓRIO' &&
    userToken.permissao_atual.id === 8;

  const isUserLaboratorio =
    userToken.permissao_atual.contexto === 'LABORATÓRIO';
  const setFiltersWhenMedicoLaboratorio = () => {
    setDisableInputEstado(true);
    setDisabledLaboratorioResultado(true);
    setDisabledProfissionalResultado(true);
    setShowTableData(true);
    return new Promise(async (resolve) => {
      let payload = {};
      setEstadosSelected(['PE']);

      const optionsCidades = await getCidades('PE');

      setOptionsMunicipios(optionsCidades);

      const getProfissional = await api.get(
        `/profissionais/estabelecimento/${userToken.estabelecimentoId}`
      );

      const dataOffProfissional = getProfissional.data?.find(
        (prof) => prof.idpessoa === userToken.pessoa.id
      );

      setValue('profissionalResultadoId', {
        ...dataOffProfissional,
        label: dataOffProfissional.nomeprofissional,
      });

      setSelectedOptionsLaboratorio([
        {
          label: userToken.estabelecimento_saude.nome_fantasia,
          value: userToken.estabelecimentoId,
        },
      ]);
      setSelectedLaboratorio([
        {
          label: userToken.estabelecimento_saude.nome_fantasia,
          value: userToken.estabelecimentoId,
        },
      ]);

      payload = {
        page: 1,
        size: 25,
        estados: 'PE',
        profissionalResultadoId: dataOffProfissional?.idprofissional,
        laboratorioLaudoId: userToken.estabelecimentoId,
      };

      setListFilterShow([
        {
          inputName: 'estado',
          value: 'Estado: PE',
        },
        {
          inputName: 'municipio',
          value: ``,
        },
        {
          inputName: 'laboratorio_responsavel',
          value: `Laboratório Responsável pelo Laudo: ${userToken.estabelecimento_saude.nome_fantasia}`,
        },

        {
          inputName: 'profissional_responsavel',
          value: `Profissional Responsável pelo Laudo: ${userToken?.pessoa?.nome}`,
        },
      ]);

      setListFilter([
        { inputName: 'estado', value: 'Estado: PE' },
        {
          inputName: 'municipio',
          value: ``,
        },
        {
          inputName: 'laboratorio_responsavel',
          value: `Laboratório Responsável pelo Laudo: ${userToken.estabelecimento_saude.nome_fantasia}`,
        },
        {
          inputName: 'profissional_responsavel',
          value: `Profissional Responsável pelo Laudo: ${dataOffProfissional?.pessoa?.nome}`,
        },
        { inputName: 'resultado', value: '' },
        { inputName: 'profissionalLaudo', value: '' },
        { inputName: 'data_ini', value: '' },
        { inputName: 'data_fin', value: '' },
        { inputName: 'search', value: '' },
      ]);
      resolve(payload);
    });
  };

  const setFiltersWhenAdminOrOthersLaboratorio = () => {
    setDisableInputEstado(true);
    setDisabledLaboratorioResultado(true);
    return new Promise(async (resolve) => {
      let payload = {};
      setEstadosSelected(['PE']);

      const optionsCidades = await getCidades('PE');

      if (optionsCidades.length > 0) {
        setOptionsMunicipios(optionsCidades);
        const municipio = await getMunicipioDetails(
          userToken.endereco.municipio_nome
        );

        setMunicipiosSelected([municipio[0].id]);

        // setEstabelecimentosSelected([userToken.estabelecimentoId]);

        const labs = [
          {
            id: 1474,
            nome_fantasia: 'LABORATORIO DA MULHER DRA MERCES PONTES CUNHA',
            cnes: 2712105,
            label: 'LABORATORIO DA MULHER DRA MERCES PONTES CUNHA',
          },
          {
            id: 4691,
            nome_fantasia: 'US 376 POLICLINICA SALOMAO KELNER',
            cnes: 6897029,
            label: 'US 376 POLICLINICA SALOMAO KELNER',
          },
          {
            id: 158,
            nome_fantasia: 'US 144 POLICLINICA CLEMENTINO FRAGA',
            cnes: '0000647',
            label: 'US 376 POLICLINICA SALOMAO KELNER',
          },
          {
            id: 61,
            nome_fantasia: 'US 143 LABORATORIO MUNICIPAL DO RECIFE',
            cnes: '0000779',
            label: 'US 143 LABORATORIO MUNICIPAL DO RECIFE',
          },
        ] as any;

        const labFromUser = labs.find(
          (lab) => lab.id === userToken.estabelecimentoId
        );

        setSelectedOptionsLaboratorio(
          labs.filter((lab) => lab.id === userToken.estabelecimentoId)
        );
        setSelectedLaboratorio([
          { value: labFromUser.id, label: labFromUser.nome },
        ]);

        payload = {
          page: 1,
          size: 25,
          estados: 'PE',
          laboratorioLaudoId: userToken.estabelecimentoId,
        };

        setListFilterShow([
          {
            inputName: 'estado',
            value: 'Estado: PE',
          },
          {
            inputName: 'municipio',
            value: `Municipio: ${userToken.endereco.municipio_nome}`,
          },
          {
            inputName: 'laboratorio_responsavel',
            value: `Laboratório Responsável pelo Laudo: ${userToken.estabelecimento_saude.nome_fantasia}`,
          },
        ]);

        setListFilter([
          { inputName: 'estado', value: 'Estado: PE' },
          {
            inputName: 'municipio',
            value: `Municipio: ${userToken.endereco.municipio_nome}`,
          },
          {
            inputName: 'laboratorio_responsavel',
            value: `Laboratório Responsável pelo Laudo: ${userToken.estabelecimento_saude.nome_fantasia}`,
          },
          { inputName: 'resultado', value: '' },
          { inputName: 'profissional_responsavel', value: '' },
          { inputName: 'profissionalLaudo', value: '' },
          { inputName: 'data_ini', value: '' },
          { inputName: 'data_fin', value: '' },
          { inputName: 'search', value: '' },
        ]);
        resolve(payload);
      }
    });
  };

  const requestDataByUserPerfil = async () => {
    const userIsPadrao =
      userToken.permissao_atual.id === 1 ||
      userToken.permissao_atual.id === 6 ||
      userToken.permissao_atual.id === 7 ||
      userToken.permissao_atual.id === 8 ||
      userToken.permissao_atual.id === 9 ||
      userToken.permissao_atual.id === 10;
    if (userIsPadrao) {
      if (!isUserLaboratorio) {
        setFiltersWhenPadrao(userToken).then((payload) => {
          getReportsResultadosCitologia(payload);
        });
      } else {
        if (isMedicoLaboratorio) {
          setFiltersWhenMedicoLaboratorio().then((payload) => {
            getReportsResultadosCitologia(payload);
          });
        }

        if (isAdminLaboratorio || isOutrosLaboratorio) {
          setFiltersWhenAdminOrOthersLaboratorio();
        }
      }
    }

    if (userToken.permissao_atual.id === 3) {
      setFiltersWhenMunicipal(userToken).then((payload) => {
        getReportsResultadosCitologia(payload);
      });
    }

    if (userToken.permissao_atual.id === 4) {
      setFiltersWhenEstadual();
    }

    if (userToken.permissao_atual.id === 2) {
      // setLoading(false);
    }

    return true;
  };

  useEffect(() => {
    if (estadosSelected.length === 1) {
      setDisabledMunicipioInput(false);
    } else {
      setDisabledMunicipioInput(true);
    }
  }, [estadosSelected]);

  useEffect(() => {
    if (municipiosSelected.length === 1) {
      setDisabledEstabelecimentoInput(false);
    } else {
      setDisabledEstabelecimentoInput(true);
    }
  }, [municipiosSelected]);

  useEffect(() => {
    setLoading(true);
    if (origin === 'tables') {
      setFiltersToRequestByTable().then((payloadToRequest) => {
        getReportsResultadosCitologia(payloadToRequest);
      });
    } else {
      requestDataByUserPerfil();
    }
  }, [origin]);

  useEffect(() => {
    setLoading(true);
    if (location.state) {
      const { payload } = location.state;
      if (payload && payload.params) {
        setFiltersFromPayload(payload.params);
        getReportsResultadosCitologia(payload.params);
      }
    } else {
      requestDataByUserPerfil();
    }
  }, [location.state]);

  const setFiltersFromPayload = (params) => {
    setEstadosSelected(params.estado ? params.estado.split(',') : []);
    setMunicipiosSelected(
      params.municipioId ? params.municipioId.split(',') : []
    );
    setEstabelecimentosSelected(
      params.estabelecimentoId ? params.estabelecimentoId.split(',') : []
    );
    setDataColetaIni(params.periodo_inicio);
    setDataColetaFinal(params.periodo_fim);

    if (params.resultado) {
      setSelectedResultado([
        { label: params.resultado, value: params.resultado },
      ]);
    }

    if (params.celula_atipica) {
      handleSetFilter(
        `Células Atípicas: ${params.celula_atipica}`,
        'celula_atipica'
      );
    }

    if (params.celula_glandular) {
      handleSetFilter(
        `Células Glandulares: ${params.celula_glandular}`,
        'celula_glandular'
      );
    }

    if (params.celula_escamosa) {
      handleSetFilter(
        `Células Escamosas: ${params.celula_escamosa}`,
        'celula_escamosa'
      );
    }
  };

  return (
    <SideMenu>
      <Container>
        <Box mt={12}>
          <ReturnButton backPage="/home" />

          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
              <Typography variant="h4" fontWeight="bold">
                Relatório Geral de Exames de Citologia - Reflexo
              </Typography>
              <Breadcrumbs
                separator={<NavigateNextIcon fontSize="small" />}
                aria-label="breadcrumb"
                style={{ marginBottom: '16px', marginTop: '16px' }}
                sx={{ marginBottom: '24px' }}
              >
                <Link
                  style={{
                    textDecoration: 'none',
                    color: '#646464',
                  }}
                  to="/home"
                >
                  Home
                </Link>
                <Typography color="text.primary">Relatórios</Typography>

                <Typography key="3" color="text.primary">
                  Relatório Geral de Exames de Citologia - Reflexo
                </Typography>
              </Breadcrumbs>
            </div>
          </div>

          <Divider />
        </Box>

        <Box mt={5}>
          <Paper variant="outlined" sx={{ borderRadius: '10px', padding: 4 }}>
            <Typography
              variant="h4"
              fontWeight="bold"
              style={{ marginBottom: '30px', fontSize: '24px' }}
            >
              Filtros
            </Typography>
            <Divider />

            <DisplayFilter style={{ marginTop: '24px' }}>
              {!listFilterShow?.find((item) => item.value !== '') && (
                <p style={{ margin: 0, width: '413px', color: '#838383' }}>
                  Os filtros selecionados aparecerão aqui.
                </p>
              )}

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  width: '100%',
                  gap: '10px',
                  flexWrap: 'wrap',
                }}
              >
                {listFilterShow.map((item) => (
                  <>
                    {item.value ? (
                      <ItemsDisplayFilter>{item.value}</ItemsDisplayFilter>
                    ) : undefined}
                  </>
                ))}
              </div>
            </DisplayFilter>

            <FiltersReportsCitologiaReflexo
              errors={errors}
              clearErrors={clearErrors}
              disabledLaboratorioResultado={disabledLaboratorioResultado}
              disabledProfissionalResultado={disabledProfissionalResultado}
              selectedOptionsLaboratorio={selectedOptionsLaboratorio}
              setSelectedOptionsLaboratorio={setSelectedOptionsLaboratorio}
              clearValuesInputs={clearValuesInputs}
              disableMunicipioInput={disableMunicipioInput}
              disableEstabelecimentoInput={disableEstabelecimentoInput}
              disableInputEstado={disableInputEstado}
              showTableData={showTableData}
              setListFilterShow={setListFilterShow}
              selectedResultado={selectedResultado}
              setTableData={setTableData}
              watch={watch}
              setError={setError}
              setShowTableData={setShowTableData}
              dtColetaFinal={dtColetaFinal}
              setPeriod={setPeriod}
              dtColetaIni={dtColetaIni}
              setDataColetaFinal={setDataColetaFinal}
              setDataColetaIni={setDataColetaIni}
              setSelectedResultado={setSelectedResultado}
              handleSubmit={handleSubmit}
              setValue={setValue}
              control={control}
              estadosSelected={estadosSelected}
              setEstadosSelected={setEstadosSelected}
              municipiosSelected={municipiosSelected}
              setMunicipiosSelected={setMunicipiosSelected}
              estabelecimentosSelected={estabelecimentosSelected}
              setEstabelecimentosSelected={setEstabelecimentosSelected}
              period={period}
              listFilter={listFilter}
              setListFilter={setListFilter}
              selectedLaboratorio={selectedLaboratorio}
              setSelectedLaboratorio={setSelectedLaboratorio}
            />

            <TableReportsCitologiaReflexo
              tableData={tableData}
              setValue={setValue}
              watch={watch}
              setTableData={setTableData}
              estadosSelected={estadosSelected}
              municipiosSelected={municipiosSelected}
              estabelecimentosSelected={estabelecimentosSelected}
              equipesSelected={equipesSelected}
              showTableData={showTableData}
            />
          </Paper>
        </Box>
      </Container>
    </SideMenu>
  );
};
