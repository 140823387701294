/* eslint-disable react-hooks/exhaustive-deps */

import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from '@mui/material';

import { Controller } from 'react-hook-form';
import { StyledAsteriskTextField } from '../../../../components/StyledAsteriskTextField/styles';
import { DisplayFilter, ItemsDisplayFilter } from '../../../Convocation/styles';
import SearchIcon from '@mui/icons-material/Search';
import { useContext, useEffect, useState } from 'react';
import { getEstados } from '../../../../lib/getEstados';
import useHandleChangesFilters from './hooks/useHandleChangesFilters';
import { getProfissionais } from '../../../../lib/profissionais';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import useRequestFilter from './hooks/useRequestFilter';
import useVerifyAccessLevel from './hooks/useVerifyAccessLevel';
import useSetPayload from './hooks/useSetPayload';
import { EstablishmentContext } from '../../../../context/EstablishmentContext';
import useClearValues from './hooks/useClearValues';
import api from '../../../../api';
import useGetDetailsFilters from './hooks/useGetDetailsFilters';
import moment from 'moment';
import useActions from '../../../../components/MultipleFilters/hooks/useActions';
import useStatesFields from '../../../../components/MultipleFilters/hooks/useStatesFields';
import MultipleFilters from '../../../../components/MultipleFilters';
import { getCidades } from '../../../../lib/getCidadesEstabelecimentos';
import { SelectWithSearch } from '../../../../components/SelectWithSearch';
import useStateFilters from './hooks/useStateFilters';
import { formatedMultiples } from '../../../../utils/requestUtils/formatMultiples';

const FiltersReportsTrackingHpv = ({
  setTableData,
  control,
  handleSubmit,
  setValue,
  watch,
  setError,
  clearErrors,
  errors,
  origin,
  tableOrigin,
  clickedCategory,
  clickedSeries,
  payloadCharts,
  setUsePayloadFromCharts,
  period,
  setPeriod,

  estadosSelected,
  setEstadosSelected,
  municipiosSelected,
  setMunicipiosSelected,
  estabelecimentosSelected,
  setEstabelecimentosSelected,
  selectedInicialAge,
  selectedFinalAge,
  selectedPrioridade,
  selectedMeio,
  selectedRealizacaoTeste,
  setSelectedInicialAge,
  setSelectedFinalAge,
  setSelectedFaixa,
  selectedFaixa,
  setSelectedPrioridade,
  setSelectedMeio,
  setSelectedRealizacaoTeste,

  setShowTableData,
  showTableData,

  disableInputEstado,
  setListFilterToShow,
  listFilterShow,
  listFilter,
  setListFilter,
}) => {
  const [disableOtherInputs, setDisableOtherInputs] = useState(true);

  const [estados, setEstados] = useState<any[]>([]);
  const [cidades, setCidades] = useState<any[]>([]);
  const [estabelecimentos, setEstabelecimentos] = useState<any[]>([]);
  const [professionalList, setProfessionalList] = useState<any[]>([]);
  const [acsList, setAcsList] = useState<any>([]);
  const [loadingAcsList, setLoadingAcsList] = useState(true);
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const [showMotivoNaoAtendimento, setShowMotivoNaoAtendimento] =
    useState(false);
  const [openModalPeriodo, setOpenModalPeriodo] = useState(false);
  const [disableFieldMunicipio, setDisableFieldMunicipio] = useState(true);
  const [disableFieldEstabelecimento, setDisableFieldEstabelecimento] =
    useState(true);

  const [loadingEstabelecimentos, setLoadingEstabelecimentos] = useState(true);
  const [loadingCidades, setLoadingCidades] = useState(true);

  const [valuePeriodoModal, setValuePeriodoModal] = useState<any>();
  const [year, setYear] = useState<any>('');
  const [valueInputPeriod, setValueInputPeriod] = useState<any>();
  const [initialDayAndMonth, setInitialDayAndMonth] = useState<any>();
  const [endDayAndMonth, setEndDayAndMonth] = useState<any>();
  const [valueInputQuadrimestreSemestre, setValueInputQuadrimestreSemestre] =
    useState<any>();

  const [disableFilters, setDisableFilters] = useState({
    estado: false,
    municipio: false,
    estabelecimento: false,
    equipe: false,
  });

  const { currentEstablishment } = useContext(EstablishmentContext);

  const {
    acsSelected,
    arrayAcs,
    searchAcs,
    allAcsOptions,
    anchorElAcs,
    disableAcsInput,

    setAnchorElAcs,
    setArrayAcs,
    setSearchAcs,
    setAcsSelected,
    setAllAcsOptions,
    setDisableAcsInput,

    anchorElEstados,
    searchEstado,
    estadosOptions,
    allEstadosOptions,

    setAnchorElEstados,
    setSearchEstado,
    setEstadosOptions,
    setAllEstadosOptions,

    allMunicipiosOptions,
    searchMunicipio,
    anchorElMunicipios,
    optionsMunicipios,
    disableMunicipioInput,

    setAllMunicipiosOptions,
    setSearchMunicipio,
    setAnchorElMunicipios,
    setOptionsMunicipios,
    setDisabledMunicipioInput,

    optionsEstabelecimentos,
    allEstabelecimentosOptions,
    searchEstabelecimentos,
    anchorElEstabelecimentos,
    disableEstabelecimentoInput,

    setOptionsEstabelecimentos,
    setAllEstabelecimentosOptions,
    setSearchEstabelecimentos,
    setAnchorEstabelecimentos,
    setDisabledEstabelecimentoInput,

    allEquipesOptions,
    searchEquipes,
    anchorElEquipe,
    arrayEquipes,
    equipesSelected,
    disableEquipeInput,
    errorsFields,

    setAllEquipesOptions,
    setSearchEquipes,
    setAnchorElEquipe,
    setArrayEquipes,
    setEquipesSelected,
    setDisableEquipeInput,
    setErrorsFields,
  } = useStatesFields();

  const { validationSubmit } = useActions();

  const {
    handleChangeUf,
    handleChangeEstabelecimento,
    handleSetFilter,
    handleChangeDatePicker,
    handleChangeRealizouAtendimento,
    getCidadesEstabelecimentos,
    getEstabelecimento,
  } = useHandleChangesFilters({
    setOptionsMunicipios,
    setEstabelecimentos,
    setValue,
    setListFilter,
    estados,
    watch,
  });

  const { loadReportsTrackingHpv } = useRequestFilter({
    setTableData,
  });

  const { setPayload } = useSetPayload({
    watch,
    period,
    estadosSelected,
    municipiosSelected,
    estabelecimentosSelected,
    acsSelected,
    selectedInicialAge,
    selectedFinalAge,
    selectedPrioridade,
    selectedMeio,
    selectedRealizacaoTeste,
    selectedFaixa,
  });

  const { permissao } = useVerifyAccessLevel({
    setValue,
    getCidadesEstabelecimentos,
    getEstabelecimento,
    currentEstablishment,
    setDisableFilters,
    handleSetFilter,
    setTableData,
    watch,
  });

  const { clearValues } = useClearValues({
    permissao,
    setValue,
    handleSetFilter,
    loadReportsTrackingHpv,
    watch,
    setTableData,
    setUsePayloadFromCharts,
    showTableData,
    clearErrors,
    setShowTableData,
    setEstadosSelected,
    setMunicipiosSelected,
    setEstabelecimentosSelected,
    setAcsSelected,
    setListFilterToShow,
    listFilter,
  });

  const { getMunicipioDetails, getEstabelecimentoDetails, getAcsDetails } =
    useGetDetailsFilters();

  const currentEstablishmentId = localStorage.getItem('id_last_establishment');
  const optionsPeriodo = [
    {
      label: 'Últimos 12 meses',
      value: 'twelve_months',
    },
    {
      label: 'Quadrimestral',
      value: 'four_months',
    },
    { label: 'Semestral', value: 'six_months' },
    { label: 'Anual', value: 'yearly' },
  ];
  const optionsQuadrimestre = [
    '1º Quadrimestre',
    '2º Quadrimestre',
    '3º Quadrimestre',
  ];
  const optionsSemestre = ['1º Semestre', '2º Semestre'];

  const setValuesInLastTwelveMonths = () => {
    setValueInputPeriod('Últimos 12 meses');
    setOpenModalPeriodo(false);
    const today = moment().format('YYYY-MM-DD');
    const twelveMonths = moment().subtract(12, 'months').format('YYYY-MM-DD');

    setPeriod({
      periodo_fim: today,
      periodo_inicio: twelveMonths,
    });
  };

  const handleSetSelectedOptions =
    ({ setSelected, description, fieldName }) =>
    (options) => {
      setSelected(options);
      const optionLabel = () => {
        if (options.length === 0) {
          return '';
        }

        if (options.length === 1) {
          return `${description}: ${options[0].label}`;
        }

        if (options.length > 1) {
          return `${description}: Vários`;
        }
      };
      handleSetFilter(optionLabel(), fieldName);
    };

  const handleChangeWhenYearSelected = (newValue) => {
    setYear(newValue);
    if (valueInputPeriod === 'Quadrimestral') {
      setValueInputQuadrimestreSemestre('1º Quadrimestre');
    }

    if (valueInputPeriod === 'Semestral') {
      setValueInputQuadrimestreSemestre('1º Semestre');
    }
    if (valueInputPeriod === 'Anual') {
      setPeriod({
        periodo_fim: `${newValue}-12-01`,
        periodo_inicio: `${newValue}-01-01`,
      });
    }
  };

  const handleChangeSemestreOrQuadrimestre = (newValue) => {
    setValueInputQuadrimestreSemestre(newValue);
    switch (newValue) {
      case '1º Semestre':
        setPeriod({
          periodo_fim: `${year}-06-01`,
          periodo_inicio: `${year}-01-01`,
        });
        break;
      case '2º Semestre':
        setPeriod({
          periodo_fim: `${year}-12-01`,
          periodo_inicio: `${year}-06-01`,
        });
        break;
      case '1º Quadrimestre':
        setPeriod({
          periodo_fim: `${year}-05-01`,
          periodo_inicio: `${year}-01-01`,
        });
        break;
      case '2º Quadrimestre':
        setPeriod({
          periodo_fim: `${year}-09-01`,
          periodo_inicio: `${year}-05-01`,
        });
        break;
      case '3º Quadrimestre':
        setPeriod({
          periodo_fim: `${year}-12-31`,
          periodo_inicio: `${year}-09-01`,
        });
        break;
      default:
        break;
    }
  };

  const handleCloseModal = () => {
    setPeriod(null);
    handleSetFilter(null, 'periodo');
    setOpenModalPeriodo(false);
    setPeriod(null);
    setYear(null);
    setValueInputPeriod(null);
    setEndDayAndMonth(null);
    setInitialDayAndMonth(null);
    setValuePeriodoModal(null);
    setValueInputQuadrimestreSemestre(null);
  };

  const setValuesQuadrimestreSelected = () => {
    setValueInputPeriod('Quadrimestral');
    setInitialDayAndMonth('-01-01');
    setEndDayAndMonth('-04-01');
    // setValueInputQuadrimestreSemestre('1º Quadrimestre');
    setPeriod(undefined);
  };
  const setValuesSemestreSelected = () => {
    setValueInputPeriod('Semestral');
    setValueInputQuadrimestreSemestre('1º Semestre');
    setInitialDayAndMonth('-01-01');
    setEndDayAndMonth('-06-01');
    setPeriod(undefined);
  };
  const setValuesAnualSelected = () => {
    setValueInputPeriod('Anual');
    setInitialDayAndMonth('-01-01');
    setEndDayAndMonth('-12-01');

    setPeriod(undefined);
  };

  const handleSelectPeriodModal = (newValue) => {
    setValuePeriodoModal(newValue);
    if (!newValue) {
      setPeriod(undefined);
      setYear(undefined);
      setValueInputPeriod(null);
    }
    switch (newValue?.value) {
      case 'twelve_months':
        setValuesInLastTwelveMonths();
        break;
      case 'four_months':
        setValuesQuadrimestreSelected();
        break;
      case 'six_months':
        setValuesSemestreSelected();

        break;
      case 'yearly':
        setValuesAnualSelected();
        break;
      default:
        break;
    }
  };
  const getEquipes = async (newValue) => {
    const response = await api.get('/equipe/estabelecimento/get', {
      params: {
        estabelecimentoId: newValue.id,
      },
    });
    setArrayEquipes(response.data);
  };

  const loadEstados = async () => {
    const response = await getEstados();
    setEstados(response);
  };

  const getProfissionaisList = async () => {
    const response = await getProfissionais({
      estabelecimentoId: String(currentEstablishmentId),
    });
    setProfessionalList(response.data);
  };

  const fnWhenFormIsValid = async () => {
    setTableData([]);
    setShowTableData(true);
    setListFilterToShow(listFilter);

    await setPayload().then((payload) => loadReportsTrackingHpv(payload));
  };

  const onSubmit = async () => {
    validationSubmit({
      estadosSelected,
      municipiosSelected,
      estabelecimentosSelected,
      fnWhenFormIsValid,
      setErrorsFields,
      setDisableOtherInputs,
      watch,
      setError,
      clearErrors,
    });
  };

  useEffect(() => {
    loadEstados();

    getProfissionaisList();
  }, []);

  // useEffect(() => {
  //   const isAllEmpty = !!listFilter.find((item) => item.value !== '') === false;

  //   if (isAllEmpty) {
  //     setListFilterToShow([]);
  //   }
  // }, [listFilter]);

  useEffect(() => {
    if (origin === 'tables') {
      setListFilterToShow(listFilter);
    }
  }, [listFilter]);

  const estabelecimentoInputValue = watch('estabelecimentoSaudeId');
  // useEffect(() => {
  //   if (!estabelecimentoInputValue) {
  //     setValue('motivo', null);
  //     setValue('idade_inicio', null);
  //     setValue('idade_final', null);
  //     setValue('realizou_atendimento', null);
  //     setValue('atendimento_status', null);
  //     setValue('inspecao_colo', null);
  //     setValue('responsavel_coleta', null);
  //     setValue('dt_coleta', null);
  //     setValue('resultado', null);
  //     setValue('responsavel_laudo', null);
  //     setValue('dt_resultado', null);
  //     setValue('search', null);

  //     initialStateListFilter.forEach((field) => {
  //       const isMunicipio = field.inputName === 'municipioId';
  //       const isEstado = field.inputName === 'estado';
  //       if (isEstado || isMunicipio) {
  //         return;
  //       } else {
  //         handleSetFilter('', field.inputName);
  //       }
  //     });
  //   }
  // }, [estabelecimentoInputValue]);

  useEffect(() => {
    if (origin === 'tables' && tableOrigin === 'faixa') {
      const firstAge = clickedSeries.split('_')[0];
      const secondAge = clickedSeries.split('_')[1];
      setValue('idade_inicio', firstAge);
      setValue('idade_final', secondAge);
    }
  }, [origin]);

  useEffect(() => {
    if (origin === 'tables') {
      if (
        clickedSeries === 'has_hpv' ||
        clickedSeries === 'need_hpv' ||
        tableOrigin === 'faixa'
      ) {
        setValue('rastreio', 'listagem');
      } else {
        setValue('rastreio', 'outros');
      }

      if (clickedSeries === 'has_hpv' || clickedSeries === 'other_hpv') {
        setValue('has_teste', 'true');
      } else if (clickedSeries === 'need_hpv') {
        setValue('has_teste', 'false');
      }
    }
  }, []);
  const estadoValue = watch('estado');
  const municipioValue = watch('municipioId');
  const estabelecimentoValue = watch('estabelecimentoSaudeId');

  useEffect(() => {
    if (!!estadoValue) {
      setDisableFieldMunicipio(false);
    } else {
      setDisableFieldMunicipio(true);
      setDisableFieldEstabelecimento(true);
    }
  }, [estadoValue]);

  useEffect(() => {
    if (!!municipioValue) {
      setDisableFieldEstabelecimento(false);
    } else {
      setDisableFieldEstabelecimento(true);
    }
  }, [municipioValue]);

  useEffect(() => {
    if (
      !!estadosSelected.length &&
      !!municipiosSelected.length &&
      !!estabelecimentosSelected.length
    ) {
      setDisableOtherInputs(false);
    } else {
      setDisableOtherInputs(true);
    }
  }, [estadosSelected, municipiosSelected, estabelecimentosSelected]);

  useEffect(() => {
    if (estabelecimentos.length > 0) {
      setLoadingEstabelecimentos(false);
    }
  }, [estabelecimentos]);

  useEffect(() => {
    if (cidades.length > 0) {
      setLoadingCidades(false);
    }
  }, [cidades]);

  useEffect(() => {
    const setFilters = async () => {
      const getOptionsEstabelecimentos = async ({
        municipioNome,
        estadoSigla,
      }) => {
        const optionsEstabelecimentos = await api.get(
          '/dashboard/rastreamento/hpv/estabelecimentos',
          {
            params: {
              estados: estadoSigla,
              municipios: municipioNome,
            },
          }
        );

        setOptionsEstabelecimentos([
          {
            id: 'null',
            municipio: '',
            nome: 'SEM VINCULAÇÃO',
            quantidade: '',
            label: '',
          },
          ...optionsEstabelecimentos.data,
        ]);

        setAllEstabelecimentosOptions([
          {
            id: 'null',
            municipio: '',
            nome: 'SEM VINCULAÇÃO',
            quantidade: '',
            label: '',
          },
          ...optionsEstabelecimentos.data,
        ]);
      };
      setShowTableData(true);
      const filteredByState = !!payloadCharts?.estados;
      const filteredByMunicipio = !!payloadCharts?.municipios;
      const filteredByEstabelecimento = !!payloadCharts?.estabelecimentos;
      const filteredByAcs = !!payloadCharts?.profissionais;

      if (clickedCategory?.length === 2) {
        const selectedEstado = estados
          ? estados?.find((estado) => estado?.sigla === clickedCategory)
          : undefined;

        setEstadosSelected([selectedEstado?.sigla]);
        handleSetFilter(selectedEstado?.sigla, 'estado');
      }
      if (
        filteredByState &&
        !filteredByMunicipio &&
        !filteredByEstabelecimento
      ) {
        const municipioData = await getMunicipioDetails(clickedCategory);

        const selectedEstado = estados
          ? estados?.find(
              (estado) => estado?.sigla === municipioData[0].estadoSigla
            )
          : undefined;

        setEstadosSelected([selectedEstado?.sigla]);
        handleSetFilter(`Estados: ${selectedEstado?.sigla}`, 'estado');

        setMunicipiosSelected([municipioData[0].id]);
        handleSetFilter(`Municipios: ${municipioData[0].nome}`, 'municipio');
      }
      if (
        filteredByState &&
        filteredByMunicipio &&
        !filteredByEstabelecimento
      ) {
        const selectedEstabelecimento = await getEstabelecimentoDetails(
          clickedCategory
        );

        setEstadosSelected([
          selectedEstabelecimento?.data[0].endereco.municipio.estado.sigla,
        ]);
        handleSetFilter(
          `Estados: ${selectedEstabelecimento?.data[0].endereco.municipio.estado.nome}`,
          'estado'
        );

        setMunicipiosSelected([
          selectedEstabelecimento?.data[0].endereco.municipio.id,
        ]);
        handleSetFilter(
          `Municipios: ${selectedEstabelecimento?.data[0].endereco.municipio.nome}`,
          'municipio'
        );

        // await getOptionsEstabelecimentos({
        //   estadoSigla:
        //     selectedEstabelecimento?.data[0].endereco.municipio.estado.sigla,
        //   municipioNome:
        //     selectedEstabelecimento?.data[0].endereco.municipio.nome,
        // });

        setEstabelecimentosSelected([selectedEstabelecimento.data[0]?.id]);
        handleSetFilter(
          `Estabelecimentos: ${selectedEstabelecimento?.data[0]?.nome_fantasia}`,
          'estabelecimento_saude'
        );
      }
      if (
        (filteredByState && filteredByMunicipio && filteredByEstabelecimento) ||
        filteredByAcs
      ) {
        const acsData = await getAcsDetails(clickedCategory);
        const estabelecimentoOffAcs = acsData.data.filter(
          (acsObj) => acsObj.estabelecimentos[0].estabelecimento.id !== 1
        )[0].estabelecimentos[0].estabelecimento;

        const acsName = acsData.data.filter(
          (acsObj) => acsObj.estabelecimentos[0].estabelecimento.id !== 1
        )[0].pessoa.nome;

        const selectedEstado = estados
          ? estados?.find(
              (estado) =>
                estado?.sigla ===
                estabelecimentoOffAcs.endereco.municipio.estadoSigla
            )
          : undefined;

        setEstadosSelected([selectedEstado?.sigla]);
        handleSetFilter(`Estados: ${selectedEstado?.nome}`, 'estado');

        await getCidadesEstabelecimentos(selectedEstado?.sigla);

        setMunicipiosSelected([estabelecimentoOffAcs.endereco.municipio.id]);
        handleSetFilter(
          `Municipios: ${estabelecimentoOffAcs.endereco.municipio.nome}`,
          'municipio'
        );

        setEstabelecimentosSelected([estabelecimentoOffAcs.id]);
        handleSetFilter(
          `Estabelecimentos: ${estabelecimentoOffAcs.nome_fantasia}`,
          'estabelecimento_saude'
        );

        setAcsSelected([acsData.data[0].id]);
        handleSetFilter(`Agente Comunitário de Saúde: ${acsName}`, 'acs');
      }
    };
    if (origin === 'tables') {
      setFilters();
    }
  }, [estados]);

  useEffect(() => {
    if (period) {
      handleSetFilter(
        `Período Inicial: ${moment(period?.periodo_inicio).format(
          'DD/MM/YYYY'
        )} - Período Final: ${moment(period?.periodo_fim).format(
          'DD/MM/YYYY'
        )}`,
        'periodo'
      );
    } else {
      handleSetFilter(null, 'periodo');
    }
  }, [period]);

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <Dialog
        open={openModalPeriodo}
        onClose={() => setOpenModalPeriodo(false)}
      >
        <DialogTitle>
          <Typography variant="h6" fontWeight="bold">
            Tipo de Período
          </Typography>
          <DialogContent sx={{ padding: '0', marginTop: '30px' }}>
            <Paper
              variant="outlined"
              sx={{
                padding: '16px',
                display: 'flex',
                flexDirection: 'column',
                gap: '16px',
              }}
            >
              <Typography variant="h6" fontWeight="bold">
                Selecione o Período para Visualização dos Dados
              </Typography>
              <Autocomplete
                placeholder="Tipo de Período"
                value={valuePeriodoModal}
                onChange={(_, newValue: any) => {
                  handleSelectPeriodModal(newValue);
                }}
                options={optionsPeriodo}
                getOptionLabel={(option) => option.label ?? ''}
                renderInput={(params) => (
                  <StyledAsteriskTextField
                    {...params}
                    fullWidth
                    label="Tipo de Período"
                    variant="outlined"
                  />
                )}
              />
              {valueInputPeriod === 'Últimos 12 meses' ||
              !valueInputPeriod ? undefined : (
                <Autocomplete
                  options={['2024', '2023', '2022']}
                  onChange={(_, newValue) => {
                    handleChangeWhenYearSelected(newValue);
                  }}
                  value={year ? year : ''}
                  renderInput={(params) => (
                    <TextField {...params} label="Selecione o Ano" />
                  )}
                />
              )}
              {}

              {(valueInputPeriod === 'Quadrimestral' ||
                valueInputPeriod === 'Semestral') && (
                <Autocomplete
                  options={
                    valueInputPeriod === 'Quadrimestral'
                      ? optionsQuadrimestre
                      : optionsSemestre
                  }
                  value={
                    valueInputQuadrimestreSemestre
                      ? valueInputQuadrimestreSemestre
                      : ''
                  }
                  onChange={(_, newValue) => {
                    handleChangeSemestreOrQuadrimestre(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={
                        valueInputPeriod === 'Quadrimestral'
                          ? 'Selecione o Quadrimestre'
                          : 'Selecione o Semestre'
                      }
                    />
                  )}
                />
              )}
            </Paper>
          </DialogContent>
        </DialogTitle>

        <DialogActions>
          <Button
            variant="outlined"
            color="error"
            onClick={() => {
              handleCloseModal();
            }}
            startIcon={<ClearIcon />}
          >
            CANCELAR
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setOpenModalPeriodo(false)}
            endIcon={<CheckIcon />}
          >
            CONFIRMAR
          </Button>
        </DialogActions>
      </Dialog>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <DisplayFilter style={{ marginTop: '24px' }}>
            <p style={{ margin: 0, width: '104px' }}>Filtro ativos:</p>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                width: '100%',
                gap: '10px',
                flexWrap: 'wrap',
              }}
            >
              {listFilterShow.map((item) => (
                <>
                  {item.value ? (
                    <ItemsDisplayFilter>{item.value}</ItemsDisplayFilter>
                  ) : undefined}
                </>
              ))}
            </div>
          </DisplayFilter>
        </Grid>
        {/* <Grid item xs={12} md={4}>
          <Controller
            name="estado"
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <StyledAsteriskTextField
                  fullWidth
                  select
                  required
                  disabled={disableFilters.estado}
                  label="UF"
                  size="small"
                  error={!!errors.estado}
                  helperText={errors.estado?.message}
                  variant="outlined"
                  onChange={(e) => {
                    setCidades([]);
                    setLoadingCidades(true);
                    handleChangeUf({ newValue: e.target.value, onChange });
                    setUsePayloadFromCharts(false);
                  }}
                  value={value ? value : ''}
                >
                  {estados.map((estado) => {
                    return (
                      <MenuItem key={estado.id} value={estado.sigla}>
                        {estado.sigla}
                      </MenuItem>
                    );
                  })}
                </StyledAsteriskTextField>
              );
            }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Controller
            name="municipioId"
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <Autocomplete
                  disabled={disableFieldMunicipio}
                  options={cidades}
                  getOptionLabel={(option) => {
                    return option?.nome;
                  }}
                  value={value ? value : null}
                  onChange={(_, newValue) => {
                    setEstabelecimentos([]);
                    setLoadingEstabelecimentos(true);
                    handleChangeCidade({
                      newValue: newValue,
                      onChange,
                      cidades,
                    });

                    setUsePayloadFromCharts(false);
                  }}
                  noOptionsText={
                    <>
                      {loadingCidades && (
                        <div
                          style={{
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginTop: '20px',
                          }}
                        >
                          <span
                            className="loader"
                            style={{ width: '40px', height: '40px' }}
                          ></span>
                        </div>
                      )}
                    </>
                  }
                  renderInput={(params) => (
                    <StyledAsteriskTextField
                      {...params}
                      label="Município"
                      size="small"
                      variant="outlined"
                      required
                      fullWidth
                      error={!!errors.municipioId}
                      helperText={errors.municipioId?.message}
                    />
                  )}
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Controller
            name="estabelecimentoSaudeId"
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <Autocomplete
                  placeholder="Estabelecimento de Saúde"
                  value={value ? value : null}
                  disabled={disableFieldEstabelecimento}
                  onChange={(_, newValue) => {
                    setUsePayloadFromCharts(false);
                    setAcsList([]);
                    setLoadingAcsList(true);
                    handleChangeEstabelecimento({
                      newValue,
                      onChange,
                      setAcsList,
                      setLoadingAcsList,
                    });
                    if (!newValue) {
                      setDisableOtherInputs(true);
                      setArrayEquipes([]);
                      setEquipesSelected([]);
                    } else {
                      setDisableOtherInputs(false);
                      getEquipes(newValue);
                    }
                  }}
                  options={estabelecimentos}
                  noOptionsText={
                    <>
                      {loadingEstabelecimentos && (
                        <div
                          style={{
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginTop: '20px',
                          }}
                        >
                          <span
                            className="loader"
                            style={{ width: '40px', height: '40px' }}
                          ></span>
                        </div>
                      )}
                    </>
                  }
                  getOptionLabel={(option) => option.nome_fantasia ?? ''}
                  renderInput={(params) => (
                    <StyledAsteriskTextField
                      {...params}
                      fullWidth
                      size="small"
                      error={!!errors.estabelecimentoSaudeId}
                      helperText={errors.estabelecimentoSaudeId?.message}
                      required
                      label="Estabelecimento de Saúde"
                      variant="outlined"
                    />
                  )}
                />
              );
            }}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <Controller
            name="profissionalAcsId"
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <Autocomplete
                  options={acsList}
                  value={value ? value : null}
                  getOptionLabel={(option: any) => {
                    return option?.nome;
                  }}
                  onChange={(_, newValue) => {
                    setUsePayloadFromCharts(false);

                    onChange(newValue);
                    clearErrors('profissionalAcsId');
                    handleSetFilter(newValue?.nome, 'profissionalAcsId');
                  }}
                  noOptionsText={
                    <>
                      {loadingAcsList && (
                        <div
                          style={{
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginTop: '20px',
                          }}
                        >
                          <span
                            className="loader"
                            style={{ width: '40px', height: '40px' }}
                          ></span>
                        </div>
                      )}
                    </>
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      disabled={disableOtherInputs}
                      size="small"
                      fullWidth
                      label="Agente comunitário de saúde"
                    />
                  )}
                />
              );
            }}
          />
        </Grid> */}
        <MultipleFilters
          disableInputEstado={disableInputEstado}
          handleSetFilter={handleSetFilter}
          mdEstado={3}
          mdMunicipio={3}
          mdEstabelecimento={3}
          mdEquipe={3}
          fieldEquipe={false}
          fieldAcs={true}
          acsSelected={acsSelected}
          arrayAcs={arrayAcs}
          searchAcs={searchAcs}
          allAcsOptions={allAcsOptions}
          setAllAcsOptions={setAllAcsOptions}
          anchorElAcs={anchorElAcs}
          disableAcsInput={disableAcsInput}
          setAnchorElAcs={setAnchorElAcs}
          setArrayAcs={setArrayAcs}
          setSearchAcs={setArrayAcs}
          setAcsSelected={setAcsSelected}
          anchorElEstados={anchorElEstados}
          searchEstado={searchEstado}
          estadosOptions={estadosOptions}
          allEstadosOptions={allEstadosOptions}
          estadosSelected={estadosSelected}
          setAnchorElEstados={setAnchorElEstados}
          setSearchEstado={setSearchEstado}
          setEstadosOptions={setEstadosOptions}
          setAllEstadosOptions={setAllEstadosOptions}
          setEstadosSelected={setEstadosSelected}
          allMunicipiosOptions={allMunicipiosOptions}
          searchMunicipio={searchMunicipio}
          anchorElMunicipios={anchorElMunicipios}
          municipiosSelected={municipiosSelected}
          optionsMunicipios={optionsMunicipios}
          disableMunicipioInput={disableMunicipioInput}
          setAllMunicipiosOptions={setAllMunicipiosOptions}
          setSearchMunicipio={setSearchMunicipio}
          setAnchorElMunicipios={setAnchorElMunicipios}
          setMunicipiosSelected={setMunicipiosSelected}
          setOptionsMunicipios={setOptionsMunicipios}
          optionsEstabelecimentos={optionsEstabelecimentos}
          allEstabelecimentosOptions={allEstabelecimentosOptions}
          searchEstabelecimentos={searchEstabelecimentos}
          anchorElEstabelecimentos={anchorElEstabelecimentos}
          estabelecimentosSelected={estabelecimentosSelected}
          disableEstabelecimentoInput={disableEstabelecimentoInput}
          setOptionsEstabelecimentos={setOptionsEstabelecimentos}
          setAllEstabelecimentosOptions={setAllEstabelecimentosOptions}
          setSearchEstabelecimentos={setSearchEstabelecimentos}
          setAnchorEstabelecimentos={setAnchorEstabelecimentos}
          setEstabelecimentosSelected={setEstabelecimentosSelected}
          allEquipesOptions={allEquipesOptions}
          searchEquipes={searchEquipes}
          anchorElEquipe={anchorElEquipe}
          arrayEquipes={arrayEquipes}
          equipesSelected={equipesSelected}
          disableEquipeInput={disableEquipeInput}
          setAllEquipesOptions={setAllEquipesOptions}
          setSearchEquipes={setSearchEquipes}
          setAnchorElEquipe={setAnchorElEquipe}
          setArrayEquipes={setArrayEquipes}
          setEquipesSelected={setEquipesSelected}
          setDisabledMunicipioInput={setDisabledMunicipioInput}
          setDisabledEstabelecimentoInput={setDisabledEstabelecimentoInput}
          setDisableEquipeInput={setDisableEquipeInput}
          setDisableAcsInput={setDisableAcsInput}
          errorsFields={errorsFields}
          setErrorsFields={setErrorsFields}
        />

        <Grid item xs={12} md={4}>
          <Controller
            name="idade_inicio"
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <TextField
                  size="small"
                  data-testid="inputInitialAge"
                  disabled={disableOtherInputs}
                  label="Idade Início"
                  onChange={(e) => {
                    setUsePayloadFromCharts(false);

                    onChange(e.target.value);
                    handleSetFilter(e.target.value, 'idade_inicio');
                  }}
                  value={value ? value : ''}
                  fullWidth
                />
              );
            }}
          />
          {/* <SelectWithSearch
            options={[
              {
                label: '25 Anos',
                value: '25',
              },
              { label: '26 Anos', value: '26' },
              { label: '27 Anos', value: '27' },
              { label: 'Fora da Faixa Etária', value: '0' },
            ]}
            label="Idade Início"
            disabled={disableOtherInputs}
            selectedOptions={selectedInicialAge}
            setSelectedOptions={setSelectedInicialAge}
            setSelected={handleSetSelectedOptions({
              setSelected: setSelectedInicialAge,
              fieldName: 'Idade Início',
            })}
            dataTestIdModal="modalResultInput"
            dataTestIdInputSearch="inputSearchResultado"
            dataTestSelectAll="selectAllResultados"
            dataTestClearAll="clearResultados"
          /> */}
        </Grid>
        <Grid item xs={12} md={4}>
          <Controller
            name="idade_final"
            data-testid="inputFinalAge"
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <TextField
                  size="small"
                  disabled={disableOtherInputs}
                  label="Idade Fim"
                  onChange={(e) => {
                    setUsePayloadFromCharts(false);

                    onChange(e.target.value);
                    handleSetFilter(e.target.value, 'idade_final');
                  }}
                  value={value ? value : ''}
                  fullWidth
                />
              );
            }}
          />

          {/* <SelectWithSearch
            options={[
              {
                label: '61 Anos',
                value: '61',
              },
              { label: '62 Anos', value: '62' },
              { label: '63 Anos', value: '63' },
            ]}
            label="Idade Final"
            disabled={disableOtherInputs}
            selectedOptions={selectedFinalAge}
            setSelectedOptions={setSelectedFinalAge}
            setSelected={handleSetSelectedOptions({
              setSelected: setSelectedFinalAge,
              fieldName: 'Idade Final',
            })}
            dataTestIdModal="modalResultInput"
            dataTestIdInputSearch="inputSearchResultado"
            dataTestSelectAll="selectAllResultados"
            dataTestClearAll="clearResultados"
          /> */}
        </Grid>
        <Grid item xs={12} md={4}>
          <SelectWithSearch
            options={[
              {
                label: '25 a 34 Anos',
                value: '25_34',
              },
              { label: '35 a 45 Anos', value: '35_45' },
              { label: '46 a 64 Anos', value: '46_64' },
            ]}
            label="Faixa Etária"
            disabled={disableOtherInputs}
            selectedOptions={selectedFaixa}
            setSelectedOptions={setSelectedFaixa}
            setSelected={handleSetSelectedOptions({
              setSelected: setSelectedFaixa,
              description: 'Faixa Etária',
              fieldName: 'faixa',
            })}
            dataTestIdModal="modalResultInput"
            dataTestIdInputSearch="inputSearchResultado"
            dataTestSelectAll="selectAllResultados"
            dataTestClearAll="clearResultados"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          {/* <Controller
            name="prioridade"
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <TextField
                  data-testid="inputTesteRealizado"
                  select
                  label="Prioridade"
                  disabled={disableOtherInputs}
                  size="small"
                  fullWidth
                  onChange={onChange}
                  value={value ? value : ''}
                >
                  <MenuItem value="1">1</MenuItem>
                  <MenuItem value="2">2</MenuItem>
                  <MenuItem value="3">3</MenuItem>
                  <MenuItem value="4">4</MenuItem>
                  <MenuItem value="5">5</MenuItem>
                  <MenuItem value="6">6</MenuItem>
                </TextField>
              );
            }}
          /> */}

          <SelectWithSearch
            options={[
              {
                label: '1',
                value: '1',
              },
              { label: '2', value: '2' },
              { label: '3', value: '3' },
              { label: '4', value: '4' },
              { label: '5', value: '5' },
              { label: '6', value: '6' },
            ]}
            label="Prioridade"
            disabled={disableOtherInputs}
            selectedOptions={selectedPrioridade}
            setSelectedOptions={setSelectedPrioridade}
            setSelected={handleSetSelectedOptions({
              setSelected: setSelectedPrioridade,
              description: 'Prioridade',
              fieldName: 'prioridade',
            })}
            dataTestIdModal="modalResultInput"
            dataTestIdInputSearch="inputSearchResultado"
            dataTestSelectAll="selectAllResultados"
            dataTestClearAll="clearResultados"
          />
        </Grid>

        <Grid item xs={12} md={4}>
          {/* <Controller
            name="rastreio"
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <TextField
                  data-testid="inputMeioRastreamento"
                  disabled={disableOtherInputs}
                  label="Meio do Rastreamento"
                  onChange={(e) => {
                    setUsePayloadFromCharts(false);

                    onChange(e.target.value);
                    handleSetFilter(e.target.value, 'listagem');
                  }}
                  value={value ? value : ''}
                  select
                  fullWidth
                  size="small"
                  variant="outlined"
                >
                  <MenuItem value="listagem">Listagem</MenuItem>
                  <MenuItem value="outros">Outros</MenuItem>
                </TextField>
              );
            }}
          /> */}
          <SelectWithSearch
            options={[
              {
                label: 'Banco de Dados',
                value: 'listagem',
              },
              { label: 'Outros', value: 'outros' },
            ]}
            label="Meio do Rastreamento"
            disabled={disableOtherInputs}
            selectedOptions={selectedMeio}
            setSelectedOptions={setSelectedMeio}
            setSelected={handleSetSelectedOptions({
              setSelected: setSelectedMeio,
              description: 'Meio do Rastreamento',
              fieldName: 'meio_rastreio',
            })}
            dataTestIdModal="modalResultInput"
            dataTestIdInputSearch="inputSearchResultado"
            dataTestSelectAll="selectAllResultados"
            dataTestClearAll="clearResultados"
          />
        </Grid>

        <Grid item xs={12} md={4}>
          {/* <StyledAsteriskTextField
            fullWidth
            size="small"
            label="Período"
            data-testid="periodoInput"
            disabled={disableOtherInputs}
            value={valueInputPeriod ? valueInputPeriod : ''}
            variant="outlined"
            InputProps={{
              endAdornment: (
                <IconButton
                  disabled={disableOtherInputs}
                  sx={{ padding: 0 }}
                  onClick={() => setOpenModalPeriodo(true)}
                >
                  <ArrowDropDownIcon />
                </IconButton>
              ),
            }}
          /> */}

          <SelectWithSearch
            options={[
              {
                label: 'Realizado',
                value: 'true',
              },
              { label: 'Não Realizado', value: 'false' },
            ]}
            label="Realização do Teste de HPV"
            disabled={disableOtherInputs}
            selectedOptions={selectedRealizacaoTeste}
            setSelectedOptions={setSelectedRealizacaoTeste}
            setSelected={handleSetSelectedOptions({
              setSelected: setSelectedRealizacaoTeste,
              description: 'Realização do Teste de HPV',
              fieldName: 'has_teste',
            })}
            dataTestIdModal="modalResultInput"
            dataTestIdInputSearch="inputSearchResultado"
            dataTestSelectAll="selectAllResultados"
            dataTestClearAll="clearResultados"
          />
        </Grid>
        <Grid item xs={12} md={9}>
          <Controller
            name="search"
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <TextField
                  disabled={disableOtherInputs}
                  data-testid="inputSearchByNameCpf"
                  label="Pesquisar"
                  placeholder="Pesquisar por Nome ou CPF da paciente"
                  size="small"
                  error={!!errors?.search}
                  helperText={errors?.search?.message}
                  value={value ? value : ''}
                  onChange={(e) => {
                    setUsePayloadFromCharts(false);

                    onChange(e.target.value);
                    handleSetFilter(e.target.value, 'search');
                  }}
                  sx={{
                    width: '100%',
                    '.MuiOutlinedInput-root': {
                      paddingRight: '0',
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <Button
                        type="button"
                        onClick={() => {
                          validationSubmit({
                            fnWhenFormIsValid,
                            estadosSelected,
                            municipiosSelected,
                            estabelecimentosSelected,
                            setErrorsFields,
                            setDisableOtherInputs,
                            watch,
                            setError,
                            clearErrors,
                          });
                        }}
                      >
                        <SearchIcon sx={{ cursor: 'pointer', color: 'gray' }} />
                      </Button>
                    ),
                  }}
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={12} md={1.5}>
          <Button
            variant="contained"
            fullWidth
            type="submit"
            data-testid="submitFilterReportsTrackingHpv"
          >
            FILTRAR
          </Button>
        </Grid>
        <Grid item xs={12} md={1.5}>
          <Button
            variant="outlined"
            color="error"
            fullWidth
            data-testid="clearFilterReportsTrackingHpv"
            onClick={() =>
              clearValues({
                setPeriod,
                setValueInputPeriod,
                setValuePeriodoModal,
                setValueInputQuadrimestreSemestre,
                setYear,
                setSelectedInicialAge,
                setSelectedFinalAge,
                setSelectedFaixa,
                setSelectedPrioridade,
                setSelectedMeio,
                setSelectedRealizacaoTeste,
              })
            }
          >
            LIMPAR
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default FiltersReportsTrackingHpv;
